//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "EditConfig",
  components: {  },
  data(){
    return {
      newConfig: {
        id: 0,
        config_code: '',
        config_value: '',
        config_description: '',
        config_type: ''
      },
      submitted: false,
    }
  },
  props: {
    default: Object,
    submitConf: Number
  },
  watch: {
    default: function(val){
      this.newConfig.id = val.id;
      this.newConfig.config_code = val.config_code;
      this.newConfig.config_value = val.config_value;
      this.newConfig.config_description = val.config_description;
      this.newConfig.config_type = val.config_type;
    },
    submitConf: function(val){
      if(val > 0){
        this.submitCreateConfig();
      }
    }
  },
  computed: {

  },
  validations: {
    newConfig: {
      config_code: { required, minLength: minLength(3), maxLength: maxLength(65) },
      config_value: { required, minLength: minLength(1), maxLength: maxLength(500) },
      config_description: { required, minLength: minLength(1), maxLength: maxLength(250) }
    }
  },
  methods: {
    submitCreateConfig(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newConfig.$touch();
      if(!this.$v.newConfig.$invalid){
        this.$emit('submitCreate', this.newConfig);
      }
    },
  },
  created() {

  }

};
