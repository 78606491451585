//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "CreateConfig",
  components: {  },
  data(){
    return {
      newConfig: {
        config_code: '',
        config_value: '',
        config_description: '',
        config_type: 'string'
      },
      submitted: false,
    }
  },
  props: {
    submitConf: Number
  },
  watch: {
    submitConf: function(val){
      if(val > 0){
        this.submitCreateConfig();
      }
    }
  },
  computed: {

  },
  validations: {
    newConfig: {
      config_code: { required, minLength: minLength(3), maxLength: maxLength(65) },
      config_value: { required, minLength: minLength(1), maxLength: maxLength(500) },
      config_description: { required, minLength: minLength(1), maxLength: maxLength(250) }
    }
  },
  methods: {
    submitCreateConfig(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newConfig.$touch();
      if(!this.$v.newConfig.$invalid){
        this.$emit('submitCreate', this.newConfig);
      }
    },
  },
  created() {

  }

};
