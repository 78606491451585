//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from "@/components/Popup";
import CreateConfig from '../../../components/admin/configs/CreateConfig.vue';
import EditConfig from '../../../components/admin/configs/EditConfig.vue';
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
    CreateConfig,
    EditConfig
  },
  data() {
    return {
      configs: [],
      createPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedId: 0,
      submitCreate: 0,
      submitEdit: 0,
      editDefault: {
        
      },
      title: this.$t('menuitems.admin.list.configs'),
      items: [
        {
          text: this.$t('menuitems.admin.text'),
        },
        {
          text: this.$t('menuitems.admin.list.configs'),
          active: true
        }
      ]
    };
  },
  validations: {
    
  },
  computed: {
    
  },
  methods: {
    deleteSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedId);

      axios
          .post(`/v1/configs/delete-main`, formData)
          .then(() => {
            this.closeDeletePopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно удален',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    showDeletePopup(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    editConfig(data){
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key].trim());
      }
      axios
          .post(`/v1/configs/update-main`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно изменен',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addEditSubmit(){
      this.submitEdit++;
    },
    closeEditPopup(){
      this.editPopup.show = false;
    },
    showEditPopup(item){
      this.editDefault = copyArrObj.copy(item);
      this.editPopup.show = true;
    },
    showCreatePopup(){
      this.createPopup.show = true;
    },
    closeCreatePopup(){
      this.createPopup.show = false;
    },
    addCreateSubmit(){
      this.submitCreate++;
    },
    createConfig(data){
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key].trim());
      }
      axios
          .post(`/v1/configs/set-main`, formData)
          .then(() => {
            this.closeCreatePopup();
            this.getConfigs();
            this.$store.dispatch("addNotification", {
              text: 'Конфиг успешно добавлен',
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getConfigs(){
      axios
          .get(`/v1/configs/get-main`)
          .then(resp => {
            this.configs = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getConfigs();
  }
};
